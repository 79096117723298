import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {ChevronDown, ChevronUp, LogOut, Menu as MenuBars,} from 'react-feather';
import Access from './accessManager';
// import config from '../config';
import user from '../img/user.svg';
import logo from "../img/logo.png";
import Clock from "react-live-clock";

class Menu extends Component {
    state = {...this.props.menu || {menu: []}}

    render() {
        return (
            <>
                <div
                    className='p-4 top-header d-md-none align-items-center d-flex flex-row bg-light w-100 text-dark shadow'>
                    <MenuBars
                        className='mr-3'
                        onClick={() => {
                            this.setState({showMenu: true});
                        }}/>
                    <div className='top-bar-text'>
                        <div className={"font-weight-bold "}>{this.state.title}</div>
                        <small><Clock format={'YYYY-MM-DD HH:mm:ss'} ticking={true} timezone={'Africa/Kinshasa'} /> Kinshasa, Congo Dem. Rep</small>

                    </div>

                </div>
                <div
                    className={'menu-overlay ' + (this.state.showMenu ? 'show' : '')}
                    onClick={() => {
                        this.setState({showMenu: false});
                    }}></div>
                <div
                    className={
                        'sidebar-wrapper border-right shadow ' +
                        (this.state.showMenu ? 'show' : '')
                    }
                    onClick={() => {
                        this.setState({showMenu: false});
                    }}>
                    <ul
                        className='navbar-nav bg-light sidebar sidebar-dark accordion position-relative'
                        id='accordionSidebar'>
                        <div>

                            <div className='d-flex flex-column user-login-card p-3 my-4 align-items-start'>
                                <div className='font-weight-bold top-bar-text'>
                                    <img src={logo} className='user-icon' alt=''/>

                                </div><hr/>
                                <div className='mt-3 text-capitalize'>
                                    {window.user.admin.name}
                                </div>

                                <div className='d-flex flex-row align-items-start justify-content-between w-100'>
                                    <div className='d-flex flex-row'>
                                        {/*<div className='action-icon'>*/}
                                        {/*    <small className='font-weight-bold'></small>*/}
                                        {/*</div>*/}

                                        <div
                                            className='action-icon'
                                            onClick={() => window.logout()}>
                                            <LogOut size={20}></LogOut>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        {this.state.menu.map((d, i) => {
                            return (
                                <li
                                    className={
                                        'nav-item ' +
                                        (this.state.active === i && d.children
                                            ? 'pb-4 no-hover '
                                            : '') +
                                        (this.state.currentRoute === d.link
                                            ? 'active'
                                            : 'text-dark')
                                    }
                                    key={i}>
                                    <Access permission={d.access}>
                                        {' '}
                                        <Link
                                            to={d.link}
                                            onClick={(e) => {
                                                if (this.state.active === i) {
                                                    this.setState({active: 0});
                                                } else {
                                                    this.setState({active: i});
                                                }
                                                if (d.children) e.preventDefault();
                                            }}
                                            className={
                                                'nav-link ' +
                                                (this.state.currentRoute === d.link
                                                    ? 'active'
                                                    : 'text-dark')
                                            }>
                                            <div className='d-flex flex-row align-items-center justify-content-between'>
                                                <div className='d-flex flex-row align-items-center'>
                                                    {d.icon}
                                                    <div className='text-dark ml-2 font-weight-bold link-text'>
                                                        {d.name}
                                                    </div>
                                                </div>
                                                {d.children && (
                                                    <div>
                                                        {this.state.active !== i && (
                                                            <ChevronDown size={18}></ChevronDown>
                                                        )}
                                                        {this.state.active === i && (
                                                            <ChevronUp size={18}></ChevronUp>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </Link>
                                    </Access>
                                    {this.state.active === i && d.children && (
                                        <div
                                            id='collapseUtilities'
                                            className='collapse show shadow-sm'
                                            aria-labelledby='headingUtilities'
                                            data-parent='#accordionSidebar'>
                                            <div className=' py-2 collapse-inner rounded mb-0'>
                                                {/* <h6 className='collapse-header'>listing:</h6> */}
                                                {d.children.map((d1, i1) => {
                                                    if (d1.type === 'hr')
                                                        return (
                                                            <>
                                                                <hr className={`${!d1.title || 'mb-1'}`}/>
                                                                {d1.title && (
                                                                    <div>
                                                                        <small
                                                                            className='text-muted text-uppercase font-weight-bold px-4'>
                                                                            {d1.title}
                                                                        </small>
                                                                    </div>
                                                                )}
                                                            </>
                                                        );

                                                    return (
                                                        <Access permission={d1.access} key={i1}>
                                                            <Link
                                                                className={
                                                                    'collapse-item font-weight-bold my-1 text-capitalize ' +
                                                                    (this.state.currentRoute === d1.link
                                                                        ? 'active'
                                                                        : 'text-dark')
                                                                }
                                                                to={d1.link}
                                                                key={i1}>
                                                                {d1.name}
                                                            </Link>
                                                        </Access>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </li>
                            );
                        })}
                        {/*  */}
                        {/* <li className='nav-item'>
            <button
              className='nav-link d-flex flex-row align-items-center bg-transparent btn'
              onClick={() => {
                if (window.confirm("Are you sure you want to logout?")) {
                  localStorage.clear();
                  this.props.history.push("/login");
                }
              }}>
              <LogOut color='white' size={18} />
              <span className='text-white ml-2'>Logout</span>
            </button>
          </li> */}
                    </ul>
                </div>
            </>
        );
    }

    componentWillReceiveProps(props) {
        if (props.history) {
            //   console.debug(props.history);
            this.setState({currentRoute: window.location.pathname});
        }
    }
}

export default withRouter(Menu);
