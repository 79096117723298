import React, {Component} from 'react';
import Table from '../../components/Table';
import {Link} from 'react-router-dom';
import Nav from '../../components/Nav';
import Filter from '../../components/filter';
import Modal from '../../components/Modal';
import ReactJson from 'react-json-view';
import Access from '../../components/accessManager';
import {XCircle} from "react-feather";

class MakerChecker extends Component {
    state = {
        tableData: {data: []},
        response: {data: []},
        tableError: false,
        query: {},
        filter: {},
        table_loading: false,
    };
    timeout = null;

    render() {
        return (
            <div className=''>
                <Nav name='Maker Checker'></Nav>
                <div className='mt-3 table-card border-0 card shado mx-3 shadow'>
                    <div className='p-4'>
                        <Filter
                            filter={[
                                // {
                                //   label: 'Status',
                                //   name: 'service_response_status',
                                //   options: [
                                //     {
                                //       label: 'All',
                                //       default: true,
                                //     },
                                //     {
                                //       label: 'Pending',
                                //       value: null,
                                //       default: true,
                                //     },
                                //     {
                                //       label: 'Approved',
                                //       value: 200,
                                //     },
                                //     {
                                //       label: 'Rejected',
                                //       value: 400,
                                //     },
                                //   ],
                                // },
                            ]}
                            // dateRange={['startdate', 'enddate']}
                            // dateRangeLabel='Date Range'
                            onChange={(filter) => {
                                let {query} = this.state;

                                this.setState({query: {...query, ...filter}});
                                setTimeout(() => {
                                    this.fetchMakerChecker();
                                }, 0);
                            }}
                        />

                        <Table
                            //   search={[ 'first_name', 'other_name']}
                            // sort="created_at"
                            // sortDirection={-1}
                            data={this.state.tableData}
                            fetch={(params) => {
                                this.setState({query: params});
                                setTimeout(() => {
                                    this.fetchMakerChecker();
                                }, 0);
                            }}
                            loading={this.state.table_loading}
                            fetchError={this.state.tableError}
                            dateRange={['startdate', 'enddate']}
                            dateRangeLabel='Date Range'
                        />
                    </div>
                </div>

                {this.state.customerJson && (
                    <Modal
                        visible={true}
                        width={800}
                        close={() => {
                            this.setState({customerJson: null});
                        }}
                    >
                        <h5 className='font-weight-bold'>Current Customer Details</h5>
                        <hr/>
                        <ReactJson
                            displayDataTypes={false}
                            displayObjectSize={false}
                            src={this.state.customerJson}
                        />
                    </Modal>
                )}

                {this.state.json && (
                    <Modal
                        visible={true}
                        width={800}
                        close={() => {
                            this.setState({json: null});
                        }}
                    >
                        <h5 className='font-weight-bold'>Data Preview</h5>
                        <hr/>
                        <ReactJson
                            displayDataTypes={false}
                            displayObjectSize={false}
                            src={this.state.json}
                        />
                    </Modal>
                )}

                {this.state.snapshot_data && (
                    <Modal
                        visible={true}
                        close={() => {
                            this.setState({snapshot_data: null});
                        }}
                    >
                        <h5 className='font-weight-bold'>Data Preview</h5>
                        <hr/>
                        <p>{this.state.snapshot_data}</p>
                    </Modal>
                )}
            </div>
        );
    }

    getAffectedCustomer = (row) => {
        console.debug(row)
        let service_payload = JSON.parse(row.service_payload);

        // Get the action for each makerchecker to help decide what function to call

        let _type = service_payload?.data?.action
        console.debug(_type)
        let type_functions = {
            "PIN_RESET": (payload) => {
                let data = payload.data;
                // let data = payload.data;

                let txt = `CustomerID:${row.uniq_key}`
                let query = `${row.uniq_key}`

                return [query, txt]
            },
            "ACTIVATE_USER": (payload) => {
                let data = payload.data;
                // let data = payload.data;

                let txt = `CustomerID:${row.uniq_key}`
                let query = `${row.uniq_key}`

                return [query, txt]
            },
            "BLOCK_USER": (payload) => {
                let data = payload.data;
                // let data = payload.data;

                let txt = `CustomerID:${row.uniq_key}`
                let query = `${row.uniq_key}`

                return [query, txt]
            },
            "UPDATE_LIMIT": (payload) => {
                let data = payload.data;
                // let data = payload.data;

                let txt = `CustomerID:${row.uniq_key}`
                let query = `${row.uniq_key}`

                return [query, txt]
            },
            "EDIT_PROFILE": (payload) => {
                let data = payload.data;
                // let data = payload.data;

                let txt = `CustomerID:${row.uniq_key}`
                let query = `${row.uniq_key}`

                return [query, txt]
            },
            "default": (payload) => {
                let data = payload.data;
                // let data = payload.data;


                let txt = `CustomerID:${data.request_customer_id || row.uniq_key}`
                let query = `${data.request_customer_id || row.uniq_key}`

                return [query, txt]
            },
        }


        let results = []
        if (_type in type_functions) {
            results = type_functions[_type](service_payload) || [{}, "Default"]
        } else {
            results = type_functions["default"](service_payload) || [{}, "Default"]
        }
        return results
    }


    fetchMakerChecker = () => {
        let {query} = this.state;

        this.setState({table_loading: true});

        window.app
            .service('service-transactions')
            .find({
                query: {
                    ...this.state.query,
                },
            })
            .then((response) => {
                response.data = response.data.map((d) => {
                    let dNew = window.fxn.clean_filtered_columns(d, this.props.data)

                    let affected_customer = this.getAffectedCustomer(d)
                    return {
                        Initiator: (
                            <Access permission='all'>
                                <Link
                                    className=''
                                    to={`/administration/admins/${d.adminObj.id}`}>
                                    {d.adminObj.name}
                                </Link>
                            </Access>
                        ),
                        "Action": (<>
                            <code>{JSON.parse(d.service_payload)?.data?.action}</code>
                        </>),
                        customer: (
                            <Access permission='all'>
                                <Link
                                    className=''
                                    to={`/management/customer/${affected_customer[0]}/details`}>
                                    {affected_customer[1]}
                                </Link>
                            </Access>
                        ),
                        ViewCustomer: d.service_payload && (
                            <button
                                className='btn btn-sm btn-outline-success font-weight-bold'
                                onClick={() =>
                                    this.viewCustomer(JSON.parse(d.service_payload).data.request_customer_id || d.uniq_key)
                                }
                            >
                                ViewCustomer
                            </button>
                        ),

                        Approval_level: d.level,
                        method: `${d.service_method}/${d.service_name}`,
                        status: ({
                            null: (<span className={"badge badge-info"}>PENDING</span>),
                            200: (<span className={"badge badge-success"}>SUCCESS</span>),
                            500: (<span className={"badge badge-danger"}>REJECTED</span>)
                        })[d.service_response_status],

                        payload: d.service_payload && (
                            <button
                                className='btn btn-sm btn-outline-success font-weight-bold'
                                onClick={() =>
                                    this.setState({json: JSON.parse(d.service_payload)?.data})
                                }
                            >
                                View Request
                            </button>
                        ),
                        response: d.service_response_txt && (
                            <button
                                className='btn btn-sm btn-outline-success font-weight-bold'
                                onClick={() =>
                                    this.setState({json: JSON.parse(d.service_response_txt)})
                                }
                            >
                                View Response
                            </button>
                        ),

                        action: !d.service_response_status ? (
                            <div className='d-flex flex-row'>
                                <Link
                                    to={`/administration/maker-checker/${d.id}/${d.service_response_status}`}
                                    className='btn btn-sm btn-round btn-outline-success px-3 mr-3'
                                >
                                    Approve
                                </Link>

                                <Link
                                    to={`/administration/maker-checker/${d.id}/${d.service_response_status}`}
                                    className='btn btn-sm btn-round btn-outline-danger px-3'
                                >
                                    Reject
                                </Link>
                            </div>
                        ) : (
                            <Link
                                to={`/administration/maker-checker/${d.id}/${d.service_response_status}`}
                                className='btn btn-sm btn-round btn-outline-primary px-3'
                            >
                                See Log Trail
                            </Link>
                        ),
                    };
                });

                this.setState({tableData: response, table_loading: false});
            })
            .catch((err) => {
                this.setState({table_loading: false});
                console.error(err);
            });
    };

    viewCustomer(uniq_key) {
        window.alert2.show({
            loader: true,
            title: 'Fetching Customer Details ...',
            buttons: false,
        });

        window.app
            .service('core/customer')
            .get(uniq_key)
            .then((response) => {
                window.alert2.hide()
                let {password, ...viewData} = response
                this.setState({customerJson: viewData})


            })
            .catch((err) => {
                this.setState({table_loading: false});
                console.error(err);
                window.alert2.show({
                    loader: false,
                    icon: <XCircle size={60} className='text-danger'></XCircle>,
                    title: 'Error',
                    message: err.message,
                    buttons: true,
                });

            });

    }
}

export default MakerChecker;