import React, {Component} from 'react';
import {Code, Database, DollarSign, Grid, Shield, UserCheck,} from 'react-feather';
import Access from "./components/accessManager";
import {Route} from "react-router-dom";
import Home from "./pages/dashboard/home";
import Preview from "./pages/dashboard/preview";
import ExecutivePreview from "./pages/dashboard/executive_preview";


import {default as Admins} from "./pages/admin/admins";
import Roles from "./pages/admin/roles";
import AuditTrail from "./pages/audit/audit";
import AuditData from "./pages/audit/auditData";
import IPAdress from "./pages/admin/ip_address";
import AdminCreate from "./pages/admin/adminCreate";
import MakerChecker from "./pages/admin/makerChecker";
import Dashboards from "./pages/dashboard/dashboards";
import MakerCheckerLogs from "./pages/admin/makerCheckerLogs";
import GroupsIcon from '@mui/icons-material/Groups';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import DatasetLinkedIcon from '@mui/icons-material/DatasetLinked';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import SettingsIcon from '@mui/icons-material/Settings';
import TESTVIEWTEMPLATE from "./pages/core/TESTVIEWTEMPLATE";
import GroupWorkIcon from '@mui/icons-material/GroupWork';

import CoreagentService from "./pages/core/agent/index";
import CoreagentDetailService from "./pages/core/agent/DetailView";
import Coreagent_accountService from "./pages/core/agent_account/index";
import Coreagent_accountDetailService from "./pages/core/agent_account/DetailView";
import Coreagent_typeService from "./pages/core/agent_type/index";
import Coreagent_typeDetailService from "./pages/core/agent_type/DetailView";
import Coreapi_activity_logService from "./pages/core/api_activity_log/index";
import Coreapi_activity_logDetailService from "./pages/core/api_activity_log/DetailView";
import Coreapi_userService from "./pages/core/api_user/index";
import Coreapi_userDetailService from "./pages/core/api_user/DetailView";
import Coreb2b_resultsService from "./pages/core/b2b_results/index";
import Coreb2b_resultsDetailService from "./pages/core/b2b_results/DetailView";
import CorecurrencyService from "./pages/core/currency/index";
import CorecurrencyDetailService from "./pages/core/currency/DetailView";
import CoredbqueueService from "./pages/core/dbqueue/index";
import CoredbqueueDetailService from "./pages/core/dbqueue/DetailView";
import CoreforexService from "./pages/core/forex/index";
import CoreforexDetailService from "./pages/core/forex/DetailView";
import Coreforex_transaction_logService from "./pages/core/forex_transaction_log/index";
import Coreforex_transaction_logDetailService from "./pages/core/forex_transaction_log/DetailView";
import CoreloanService from "./pages/core/loan/index";
import CoreloanDetailService from "./pages/core/loan/DetailView";
import Coreloan_controlService from "./pages/core/loan_control/index";
import Coreloan_controlDetailService from "./pages/core/loan_control/DetailView";
import Corempesa_disburseService from "./pages/core/mpesa_disburse/index";
import Corempesa_disburseDetailService from "./pages/core/mpesa_disburse/DetailView";
import Corempesa_disburse_completeService from "./pages/core/mpesa_disburse_complete/index";
import Corempesa_disburse_completeDetailService from "./pages/core/mpesa_disburse_complete/DetailView";
import CorerepaymentService from "./pages/core/repayment/index";
import CorerepaymentDetailService from "./pages/core/repayment/DetailView";
import CorestatusService from "./pages/core/status/index";
import CorestatusDetailService from "./pages/core/status/DetailView";
import CoresuspenseService from "./pages/core/suspense/index";
import CoresuspenseDetailService from "./pages/core/suspense/DetailView";
import Coretransaction_logService from "./pages/core/transaction_log/index";
import Coretransaction_logDetailService from "./pages/core/transaction_log/DetailView";
import Redirect from "react-router-dom/es/Redirect";

export class NavRoutes extends Component{
    render(){
        return (
            <div className='d-flex flex-fill flex-column'>
                <Access permission='all'>
                    <Route path='/' exact component={Home}/>
                </Access>

                <Access permission='all'>
                    <Route path='/home' exact component={Home}/>
                </Access>

                <Access permission='VIEW_ANALYTICS'>
                    <Route path='/management/analytics' exact component={Preview}/>
                </Access>
                <Access permission='VIEW_EXECUTIVE_ANALYTICS'>
                    <Route path='/management/executive_analytics' exact component={ExecutivePreview}/>
                </Access>

                <Access permission='ADMIN_READ'>
                    <Route path='/administration/admins' exact component={Admins}/>
                </Access>

                <Access permission='ROLES_READ'>
                    <Route path='/administration/roles' exact component={Roles}/>
                </Access>


                <Access permission='AUDIT_READ'>
                    <Route path='/administration/audit' exact component={AuditTrail}/>
                </Access>

                <Access permission='AUDIT_READ'>
                    <Route path='/administration/audit-data' exact component={AuditData}/>
                </Access>


                <Access permission='IP_ADDRESS_READ'>
                    <Route path='/administration/ip' exact component={IPAdress}/>
                </Access>


                <Access permission='ADMIN_CREATE'>
                    <Route path='/administration/admin-create' exact component={AdminCreate}/>
                </Access>

                <Access permission='MAKER_CHECKER_READ'>
                    <Route path='/administration/maker-checker' exact component={MakerChecker}/>
                </Access>

                <Access permission='all'>
                    <Route path='/dashboards/:type' exact component={Dashboards}/>
                </Access>
                <Access permission='MAKER_CHECKER_CONFIRM'>
                    <Route
                        path='/administration/maker-checker/:id/:status'
                        exact
                        component={MakerCheckerLogs}
                    />
                </Access>



                <Access permission='all'>
                    <Route path='/management/TESTVIEWTEMPLATE' component={TESTVIEWTEMPLATE}/>
                </Access>
                <Access permission='all'><Route path='/management/agent' component={CoreagentService}/></Access>
                <Access permission='all'><Route path='/management/view/agent/:id/:tab' component={CoreagentDetailService}/></Access>
                <Access permission='all'><Route path='/management/agent_account' component={Coreagent_accountService}/></Access>
                <Access permission='all'><Route path='/management/view/agent_account/:id/:tab' component={Coreagent_accountDetailService}/></Access>
                <Access permission='all'><Route path='/management/agent_type' component={Coreagent_typeService}/></Access>
                <Access permission='all'><Route path='/management/view/agent_type/:id/:tab' component={Coreagent_typeDetailService}/></Access>
                <Access permission='all'><Route path='/management/api_activity_log' component={Coreapi_activity_logService}/></Access>
                <Access permission='all'><Route path='/management/view/api_activity_log/:id/:tab' component={Coreapi_activity_logDetailService}/></Access>
                <Access permission='all'><Route path='/management/api_user' component={Coreapi_userService}/></Access>
                <Access permission='all'><Route path='/management/view/api_user/:id/:tab' component={Coreapi_userDetailService}/></Access>
                <Access permission='all'><Route path='/management/b2b_results' component={Coreb2b_resultsService}/></Access>
                <Access permission='all'><Route path='/management/view/b2b_results/:id/:tab' component={Coreb2b_resultsDetailService}/></Access>
                <Access permission='all'><Route path='/management/currency' component={CorecurrencyService}/></Access>
                <Access permission='all'><Route path='/management/view/currency/:id/:tab' component={CorecurrencyDetailService}/></Access>
                <Access permission='all'><Route path='/management/dbqueue' component={CoredbqueueService}/></Access>
                <Access permission='all'><Route path='/management/view/dbqueue/:id/:tab' component={CoredbqueueDetailService}/></Access>
                <Access permission='all'><Route path='/management/forex' component={CoreforexService}/></Access>
                <Access permission='all'><Route path='/management/view/forex/:id/:tab' component={CoreforexDetailService}/></Access>
                <Access permission='all'><Route path='/management/forex_transaction_log' component={Coreforex_transaction_logService}/></Access>
                <Access permission='all'><Route path='/management/view/forex_transaction_log/:id/:tab' component={Coreforex_transaction_logDetailService}/></Access>
                <Access permission='all'><Route path='/management/loan' component={CoreloanService}/></Access>
                <Access permission='all'><Route path='/management/view/loan/:id/:tab' component={CoreloanDetailService}/></Access>
                <Access permission='all'><Route path='/management/loan_control' component={Coreloan_controlService}/></Access>
                <Access permission='all'><Route path='/management/view/loan_control/:id/:tab' component={Coreloan_controlDetailService}/></Access>
                <Access permission='all'><Route path='/management/mpesa_disburse' component={Corempesa_disburseService}/></Access>
                <Access permission='all'><Route path='/management/view/mpesa_disburse/:id/:tab' component={Corempesa_disburseDetailService}/></Access>
                <Access permission='all'><Route path='/management/mpesa_disburse_complete' component={Corempesa_disburse_completeService}/></Access>
                <Access permission='all'><Route path='/management/view/mpesa_disburse_complete/:id/:tab' component={Corempesa_disburse_completeDetailService}/></Access>
                <Access permission='all'><Route path='/management/repayment' component={CorerepaymentService}/></Access>
                <Access permission='all'><Route path='/management/view/repayment/:id/:tab' component={CorerepaymentDetailService}/></Access>
                <Access permission='all'><Route path='/management/status' component={CorestatusService}/></Access>
                <Access permission='all'><Route path='/management/view/status/:id/:tab' component={CorestatusDetailService}/></Access>
                <Access permission='all'><Route path='/management/suspense' component={CoresuspenseService}/></Access>
                <Access permission='all'><Route path='/management/view/suspense/:id/:tab' component={CoresuspenseDetailService}/></Access>
                <Access permission='all'><Route path='/management/transaction_log' component={Coretransaction_logService}/></Access>
                <Access permission='all'><Route path='/management/view/transaction_log/:id/:tab' component={Coretransaction_logDetailService}/></Access>
                <Redirect to='/home' />

            </div>

        );
    }
}
export function getMenuList() {
    return {
        title: 'SOS-Buka',
        menu: [
            {
                name: 'Home',
                icon: <AutoModeIcon size={20}/>,
                link: '/home',
                access: 'all',
            },
            {
                name: 'Executive Analytics',
                icon: <Grid size={20}/>,
                link: '/management/executive_analytics',
                access: 'VIEW_EXECUTIVE_ANALYTICS',
            },
            {
                name: 'Analytics',
                icon: <Grid size={20}/>,
                link: '/management/analytics',
                access: 'VIEW_ANALYTICS',
            },
            {
                name: 'Agents',
                link: '/management/agent',
                icon: <GroupsIcon size={20}/>,
                access: 'VIEW_AGENTS'
            },
            {
                name: 'Accounts',
                link: '/management/agent_account',
                icon: <GroupWorkIcon size={20}/>,
                access: 'VIEW_AGENT_ACCOUNTS'
            },
            {
                name: 'Loans',
                link: '/management/loan',
                icon: <CreditScoreIcon size={20}/>,
                access: 'VIEW_LOANS'
            },

            {
                name: 'Modules',
                icon: <Grid size={20}/>,
                link: '/',
                access: 'ALL_MODULES',
                children: [
                    {name: 'agent',link: '/management/agent',access: 'all'},
                    {name: 'agent_account',link: '/management/agent_account',access: 'all'},
                    {name: 'agent_type',link: '/management/agent_type',access: 'all'},
                    {name: 'api_activity_log',link: '/management/api_activity_log',access: 'all'},
                    {name: 'api_user',link: '/management/api_user',access: 'all'},
                    {name: 'b2b_results',link: '/management/b2b_results',access: 'all'},
                    {name: 'currency',link: '/management/currency',access: 'all'},
                    {name: 'dbqueue',link: '/management/dbqueue',access: 'all'},
                    {name: 'forex',link: '/management/forex',access: 'all'},
                    {name: 'forex_transaction_log',link: '/management/forex_transaction_log',access: 'all'},
                    {name: 'loan',link: '/management/loan',access: 'all'},
                    {name: 'loan_control',link: '/management/loan_control',access: 'all'},
                    {name: 'mpesa_disburse',link: '/management/mpesa_disburse',access: 'all'},
                    {name: 'mpesa_disburse_complete',link: '/management/mpesa_disburse_complete',access: 'all'},
                    {name: 'repayment',link: '/management/repayment',access: 'all'},
                    {name: 'status',link: '/management/status',access: 'all'},
                    {name: 'suspense',link: '/management/suspense',access: 'all'},
                    {name: 'transaction_log',link: '/management/transaction_log',access: 'all'},
                ],
            },


            {
                name: 'Administration',
                icon: <Shield size={20}/>,
                link: '/administration/admins',
                access: 'ADMIN_READ',
                children: [
                    {
                        name: 'Admins',
                        link: '/administration/admins',
                        access: 'ADMIN_READ',
                    },
                    {
                        name: 'Maker Checker',
                        link: '/administration/maker-checker',
                        access: 'MAKER_CHECKER_READ',
                    },
                    {
                        name: 'IP Adresses',
                        icon: <Code size={20}/>,
                        link: '/administration/ip',
                        access: 'IP_ADDRESS_READ',
                    },
                    {
                        name: 'Audit',
                        icon: <UserCheck size={20}/>,
                        link: '/administration/audit',
                        access: 'AUDIT_READ',
                    },

                    {
                        name: 'Roles',
                        link: '/administration/roles',
                        access: 'ROLES_READ',
                    },
                ],
            },
        ],
        currentRoute: window.location.pathname,
        active: 0,
    }
}

  
