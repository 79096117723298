import React, {Component} from 'react';
import Table from '../../components/Table';
import Nav from '../../components/Nav';
import moment from 'moment';
import Modal from "../../components/Modal";
import {CheckCircle, XCircle} from "react-feather";
import Filter from "../../components/filter";

class Admins extends Component {
    state = {
        tableData: {data: []},
        response: {data: []},
        tableError: false,
        query: {},
        filter: {},
        table_loading: false,
        editModal: false,
    };
    timeout = null;

    render() {
        return (
            <div className=''>
                <Nav
                    name='System User'
                    buttons={[
                        {
                            text: 'Add System User',
                            link: '/administration/admin-create',
                        },
                    ]}></Nav>
                <div className='mt-3 table-card border-0 card shado mx-3 shadow'>
                    <div className='p-4'>

                        <Table
                            search={['name']}
                            // sort="created_at"
                            // sortDirection={-1}
                            refreshAllowed={true}
                            data={this.state.tableData}
                            fetch={(params) => {
                                this.setState({query: params});
                                setTimeout(() => {
                                    this.fetchAdmins();
                                }, 0);
                            }}
                            loading={this.state.table_loading}
                            fetchError={this.state.tableError}
                        />
                        <Modal
                            visible={this.state.editModal}
                            close={() => this.setState({editModal: false})}>
                            <h5 className="font-weight-bold" style={{margin: "10px"}}>{this.state.title}</h5>

                            <form onSubmit={this.editRequest}>

                                <div className="mx-3 d-inline-block mb-3">
                                    <span className="ml-1 mb-2 d-block text-capitalize">Name</span>
                                    <input className="form-control bg-light text-input" name="name" id="name"
                                           type="text" value={this.state.name} onChange={this.handleChange}
                                           style={{width: '400px'}}/>
                                </div>
                                <div className="mx-3 d-inline-block mb-3">
                                    <span className="ml-1 mb-2 d-block text-capitalize">Email</span>
                                    <input className="form-control bg-light text-input" name="email" id="email"
                                           type="text" value={this.state.email} onChange={this.handleChange}
                                           style={{width: '400px'}}/>

                                </div>
                                <div className="mx-3 d-inline-block mb-3">
                                    <span className="ml-1 mb-2 d-block text-capitalize">Password</span>
                                    <input className="form-control bg-light text-input" type="text" name="password"
                                           id="password" value={this.state.password} onChange={this.handleChange}
                                           style={{width: '400px'}}/>
                                </div>
                                <div className="mx-3 d-inline-block mb-3">
                                    <span className="ml-1 mb-2 d-block text-capitalize">Group</span>
                                    <select style={{width: '400px'}} className="form-control bg-light text-input"
                                            name="group_id" id="group_id" type="text" value={this.state.group_id}
                                            onChange={this.handleChange}>
                                        {window.groups.map((d) => {
                                            return <option value={d.id}>{d.name}</option>;
                                        })}
                                    </select>
                                </div>


                                <div
                                    className="my-3 d-flex flex-row justify-content-between mt-5 justify-content-between">
                                    <button
                                        className="btn btn-primary px-3 px-md-5 ml-3 font-weight-bold btn-round">Edit
                                    </button>
                                </div>
                            </form>
                        </Modal>
                    </div>
                </div>
            </div>
        );
    }

    fetchAdmins = () => {
        this.setState({table_loading: true});

        window.app
            .service('prerequisites/admins')
            .find({
                query: {
                    ...this.state.query,
                },
            })
            .then((response) => {
                response.data = response.data.map((d) => {
                    let dNew = window.fxn.clean_filtered_columns(d, this.props.data)

                    return {
                        name: d.name,
                        email: d.email,
                        group: d.group?.name,
                        secondFactor_selected: d.secondFactor_selected,
                        secondFactor_status: d.secondFactor_status,
                        createdAt: d.createdAt && moment(d.createdAt).format('YYYY-MM-DD hh:mm:ss'),
                        action: (
                            <div className="d-flex flex-row">
                                <button
                                    onClick={() => {
                                        this.setState({
                                            editModal: true,
                                            activeDetails: d,
                                            name: d.name,
                                            email: d.email,
                                            id: d.id,
                                            group: d.group?.name,
                                            secondFactor_selected: d.secondFactor_selected,
                                            secondFactor_status: d.secondFactor_status
                                        });

                                    }}
                                    className="option-card no-wrap pr-3 d-md-flex d-inline-block my-2 flex-row btn align-items-center btn-primary btn-round mr-3"
                                >
                                    Edit
                                </button>

                            </div>
                        )
                    };
                });
                console.log("ADMINS", response)

                this.setState({tableData: response, table_loading: false});
            })
            .catch((err) => {
                this.setState({table_loading: false});
                console.error(err);
            });
    };
    editRequest = (event) => {
        event.preventDefault();
        let id = this.state.id;
        let data = {
            id: this.state.id,
            name: this.state.name,
            email: this.state.email,
            password: this.state.password,
            group_id: this.state.group_id
        };
        if (
            !window.confirm("Are you sure that you want to Eit this user?")
        )
            return false;

        window.alert2.show({
            loader: true,
            title: 'Request Action Executing ...',
            buttons: false,
        });

        window.app
            .service('prerequisites/admins')
            .patch(id, data)
            .then((response) => {
                this.setState(response);

                window.alert2.show({
                    loader: false,
                    icon: <CheckCircle size={60}></CheckCircle>,
                    title: 'Admin updated Successfully',
                    buttons: true,
                    onSubmit: () => {
                        window.location.reload();
                    },
                });
            })
            .catch((err) => {
                window.alert2.show({
                    loader: false,
                    icon: <XCircle size={60} className='text-danger'></XCircle>,
                    title: 'Error',
                    message: err.message,
                    buttons: true,
                });
                console.error(err);
            });

    };
    handleChange = (e) => {
        const {id, value} = e.target
        console.debug(e.target.id);
        this.setState(prevState => ({
            ...prevState,
            [id]: value
        }))
    }
}

export default Admins;