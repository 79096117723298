import React, {Component, useEffect} from 'react';
import start from '../../img/work.svg';
import Nav from "../../components/Nav";
import Table from "../../components/Table";
import {Link} from "react-router-dom";
const Home = props => {
    const {className, value,_obj, ...rest} = props;

    const ref = React.useRef();
    const [height, setHeight] = React.useState("0px");
    const onLoad = () => {
        setHeight(ref.current.contentWindow.document.body.scrollHeight + "px");
    };

    useEffect(() => {

    }, []);


    return (
            <div>
                <Nav name={''}></Nav>

                <div className='mx-auto'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='mt-3 table-card border-0 card mx-3 shadow-none'>
                                <iframe
                                    title='123'
                                        src={window.bi_endpoint+"/superset/dashboard/3/?standalone=true&native_filters_key=ZUSjwnwD9BJrIEA4jCeOnSRBxdEcn07GhoXWnt3X2DCpm0TxISt9jv0FlEN8aLVd"}
                                    className='w-100 bg-white'
                                    height={1000}
                                    frameBorder={0}
                                ></iframe>
                            </div>
                        </div>
                    </div>



                </div>

            </div>
        );

}

export default Home;
